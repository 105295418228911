import React, { useState, useRef, Fragment } from "react"
import Img from "gatsby-image"
import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"

import Message from "elements/Message"
import Button from "elements/Button"
import Card from "elements/Card"

import { handleFileRead } from "../../../Elements/UploadDocumentDropzone/services/dropzone"
import styles from "../../../Elements/utils/elements.module.scss"

const MAX_FILE_SIZE_IN_BYTES = 3145728
const MAX_FILE_COUNT = 1

const UpdateDropzone = ({
  label,
  icon,
  docType,
  maxFileCount = MAX_FILE_COUNT,
  maxFileSize = MAX_FILE_SIZE_IN_BYTES,
  currentFilesList = [],
  onUploadSuccess = null,
  uploadOldConsultations,
  setUploadErrors,
  updateConsults,
  setUpdateConsults,
  setLoading,
  formFields,
}) => {
  const data = useStaticQuery(graphql`
    {
      id: file(relativePath: { eq: "icons/upload__id.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      document: file(relativePath: { eq: "icons/upload__document.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prescription: file(
        relativePath: { eq: "icons/upload__prescription.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const [messages, setMessages] = useState([])
  const [uploadProgress, setUploadProgress] = useState(0)
  const fileInputRef = useRef(null)
  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  let [uploadCSVEvent, setUploadCSVEvent] = useState(null)

  if (!uploadCSVEvent)
    return (
      <Fragment>
        <div
          className={classNames("mb-1", styles["dropzone"])}
          onClick={handleFileChooser}
          onKeyDown={(event) => {
            if (event.key === "Enter") handleFileChooser()
          }}
          role="button"
          tabIndex={0}
        >
          <input
            type="file"
            className={styles["dropzone__input"]}
            ref={fileInputRef}
            onChange={(event) => {
              if (onUploadSuccess) {
                setUploadCSVEvent(event.target.files[0])
                onUploadSuccess({
                  event: event.target.files[0],
                  setMessages,
                  upload: true,
                  setUploadErrors,
                  setUploadCSVEvent,
                  setUpdateConsults,
                  setUploadProgress,
                  setLoading,
                  formFields,
                })
              } else {
                handleFileRead({
                  event,
                  maxFileCount,
                  maxFileSize,
                  currentFilesList,
                  docType,
                  onUploadSuccess,
                  setMessages,
                })
              }
            }}
            multiple
            accept={docType}
            value=""
          />
          <div
            className={classNames(
              "has-background-light mb-0 is-flex is-align-items-center is-justify-content-center",
              styles["dropzone__top"]
            )}
          >
            <Img fixed={data[icon || "prescription"].childImageSharp.fixed} />
          </div>
          <div
            className={classNames(
              "has-background-primary mt-0 mb-0 is-flex is-align-items-center is-justify-content-center",
              styles["dropzone__bottom"]
            )}
          >
            <p className="has-text-white is-size-6 has-text-centered has-text-weight-bold">
              {label || "Upload Document"}
            </p>
          </div>
        </div>
        {messages?.length > 0 && (
          <Message color="warning">
            <p className="is-size-6 has-text-black">
              <ul>
                {messages.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            </p>
          </Message>
        )}
      </Fragment>
    )
  else
    return (
      <Fragment>
        <Card className="mt-2 mb-4">
          {uploadProgress === 0 ? (
            <div className="columns is-vcentered">
              <div className="column is-one-quarter">
                <Img
                  fixed={data[icon || "prescription"].childImageSharp.fixed}
                />
              </div>
              <div className="column">
                <h4>{uploadCSVEvent.name}</h4>
              </div>
            </div>
          ) : (
            <progress
              class="progress is-primary"
              value={uploadProgress}
              max="100"
            />
          )}
        </Card>
        <div className="is-flex is-justify-content-center mb-4">
          <Button
            color="danger"
            className={classNames("column pb-0 pt-0 is-3 mx-2")}
            onClick={() => {
              setUploadCSVEvent(null)
              setUpdateConsults([])
            }}
            isDisabled={uploadProgress}
          >
            Remove
          </Button>
          <Button
            color="primary"
            className={classNames("column pb-0 pt-0 is-3 mx-2")}
            onClick={async () => {
              if (uploadCSVEvent) {
                let successMessage = []
                await uploadOldConsultations({
                  data: updateConsults,
                  setUploadErrors,
                  setUploadCSVEvent,
                  setUploadProgress,
                })

                successMessage.push({
                  type: "SUCCESS",
                  message: "Upload successful",
                  color: "success",
                })

                setUploadErrors(successMessage)
                setUpdateConsults([])
              }
            }}
            isDisabled={uploadProgress}
          >
            Upload
          </Button>
        </div>
      </Fragment>
    )
}

export default UpdateDropzone
