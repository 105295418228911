export const options = {
  customToolbarSelect: () => {},
  onRowClick: () => {},
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  customSearch: (searchQuery, currentRow) => {
    let isFound = false
    currentRow.forEach((col) => {
      if (
        col &&
        col.data
          .toString()
          .toLowerCase()
          .replace(/\s/g, "")
          .indexOf(searchQuery.toLowerCase().replace(/\s/g, "")) >= 0
      ) {
        isFound = true
      }
    })
    return isFound
  },
}
