import Papa from "papaparse"
import firebase from "firebase"
import _ from "lodash"

import {
  addDefaultValues,
  convertDatesToISO,
  convertToBoolean,
  isValidConsultation,
} from "./validateConsultations"

import { removeFalsyProperties } from "./general"

import { unflatten } from "./general"
import { formatDateField } from "../../../../../services/date"

const getFieldTypes = (formFields) => {
  let fieldTypes = {}

  formFields.forEach((field) => {
    fieldTypes = { ...fieldTypes, [field.name]: field?.formFieldType[0] }
  })

  fieldTypes = {
    ...fieldTypes,
    diagnosis: "select",
  }

  return fieldTypes
}

const formatArchiverValues = (consultations, formFields) => {
  let fieldTypes = getFieldTypes(formFields)

  consultations.forEach((consultation) => {
    for (let key in consultation) {
      switch (fieldTypes[key]) {
        case "date":
          consultation[key] = {
            month: formatDateField(consultation[key], "month"),
            date: formatDateField(consultation[key], "date"),
            year: formatDateField(consultation[key], "year"),
          }
          break
        case "select":
          consultation[key] = {
            label: consultation[key]?.label || consultation[key],
            value: consultation[key]?.value || consultation[key],
          }
          break
        case "multiselect":
          consultation[key] = [
            {
              label: consultation[key]?.label || consultation[key],
              value: consultation[key]?.value || consultation[key],
            },
          ]
          break
        case "text":
        case "static":
        case "textarea":
          if (!!consultation[key] && typeof consultation[key] === "string")
            consultation[key] = consultation[key].trim()
        default:
          break
      }
    }

    consultation["isDraft"] = false
  })

  return consultations
}

export const parseConsultations = ({
  event,
  setUpdateErrors,
  setUpdateConsults,

  upload = false,
  formFields,
}) => {
  let file = event
  let formattedConsultations

  Papa.parse(file, {
    header: true,
    worker: true,
    complete: async (results) => {
      let nonEmptyRows = results.data.filter((row) =>
        Object.values(row).some((val) => val !== "")
      )
      for (let i = 0; i < nonEmptyRows.length; i++) {
        convertDatesToISO({ data: nonEmptyRows[i] })
        nonEmptyRows[i] = addDefaultValues({ data: nonEmptyRows[i] })
        nonEmptyRows[i] = unflatten(nonEmptyRows[i])
        nonEmptyRows[i] = convertToBoolean({ data: nonEmptyRows[i] })
      }

      // let consultationIsValid = isValidConsultation({
      //   data: nonEmptyRows,
      //   // setUploadErrors,
      // })

      if (upload) {
        let formattedRows = formatArchiverValues(nonEmptyRows, formFields)

        setUpdateConsults(formattedRows)
      }
    },
  })
}

export const uploadOldConsultations = async ({
  data,
  setUploadErrors,
  setUploadCSVEvent,
  setUploadProgress,
}) => {
  let batchRefs = []
  let progress = 0
  data.forEach((element) => {
    let usersRef = firebase
      .firestore()
      .collection("users")
      .doc(element.userId)
      .collection("consultations")

    if (element.firestoreDocumentId) {
      usersRef = usersRef.doc(element.firestoreDocumentId)
    } else {
      usersRef = usersRef.doc() // Firestore will generate a new document ID
    }

    let meds = element?.medicines

    delete element?.medicines

    let batchSize = 0

    let ref = {
      ref: usersRef,
      data: { ...element, programCode: "MGX1SOLMH" },
    }

    batchRefs.push(ref)
    batchSize += 0.05

    if (meds?.length) {
      meds.forEach((element) => {
        if (!element.drug && !element.signa) {
          return
        }

        let ref = {
          ref: usersRef.collection("medicines").doc(),
          data: element,
        }

        batchRefs.push(ref)
        batchSize += 0.05
      })
    }

    progress += batchSize
    setUploadProgress(Math.floor(progress))
  })

  let batches = processBatch({ batchRefs, setUploadErrors, setUploadProgress })

  await Promise.all(batches)

  setUploadProgress(0)
  setUploadCSVEvent(null)
}

const processBatch = ({ batchRefs, size = 500 }) => {
  //max chunk of firebase is 500

  let batches = _.chunk(batchRefs, size).map((chunk) => {
    let batch = firebase.firestore().batch()

    chunk.forEach((ref) => {
      // let finalData = removeFalsyProperties(ref.data)

      batch.set(ref.ref, { ...ref.data }, { merge: true })
    })

    return batch.commit()
  })

  return batches
}
