import _ from "lodash"

export const unflatten = (flattenedObject) => {
  let result = {}
    _.keys(flattenedObject).forEach(function (key, value){    
        _.set(result, key, flattenedObject[key])        
    })
  return result
}

export const removeFalsyProperties = (obj) => {
  for (const key in obj) {
    if (!obj[key]) {
      delete obj[key]
    }
  }
  return obj
}