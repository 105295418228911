import React, { useState, Fragment } from "react"

import DashboardLayout from "layout/DashboardLayout"
import Message from "elements/Message"
import UpdateDropzone from "./UpdateDropzone"
import Datatable from "elements/Datatable"
import Loading from "elements/Loading"

import {
  parseConsultations,
  uploadOldConsultations,
} from "./services/updateConsultations"
import { data } from "./services/getUpdateConsultationsTableData"
import { options } from "./services/getUpdateConsultationsTableOptions"
import { columns } from "./services/getUpdateConsultationsTableColumns"

const Update = ({ pageContext }) => {
  const [uploadErrors, setUploadErrors] = useState([])
  const [updateConsults, setUpdateConsults] = useState([])
  const [loading, setLoading] = useState(false)

  let { module } = pageContext

  return (
    <DashboardLayout
      seoTitle={module.title}
      title={module.title}
      pageContext={pageContext}
    >
      <div>
        <UpdateDropzone
          label="Upload CSV"
          icon={null}
          docType=".csv"
          onUploadSuccess={parseConsultations}
          uploadOldConsultations={uploadOldConsultations}
          setUploadErrors={setUploadErrors}
          formFields={pageContext?.formFields}
          updateConsults={updateConsults}
          setUpdateConsults={setUpdateConsults}
          setLoading={setLoading}
        />

        {uploadErrors.length ? (
          <Message>
            <ul>
              {uploadErrors.map((error) => {
                //convert this to message
                return (
                  <li>
                    {error.type}: {error.message}
                  </li>
                )
              })}
            </ul>
          </Message>
        ) : null}
      </div>
      {updateConsults.length ? (
        <Fragment>
          <h2 className="mt-4 mr-1 mb-3 has-text-primary is-size-3-mobile">
            Consultations to be Updated
          </h2>
          <Datatable
            data={data(updateConsults)}
            options={options}
            columns={columns}
          />
        </Fragment>
      ) : null}
      {loading ? <Loading /> : null}
    </DashboardLayout>
  )
}

export default Update
