import moment from "moment"

export const isFutureDate = ({ date }) => {
  let dateToday = new Date()
  dateToday.setDate(dateToday.getDate() - 1)

  if (date) return dateToday <= moment(date).toDate()
  return false
}

export const formatDate = (date) => {
  return moment(date).format("MM-DD-YYYY")
}

export const formatDateWithDay = (date) => {
  return moment(date).format("MMM DD (ddd) hh:mm A")
}

export const formatUnix = (unix, format) => {
  switch (format) {
    case "time":
      if (moment.unix(unix).format("hh:mm A") !== "Invalid date")
        return moment.unix(unix).format("hh:mm A")
      else return "Invalid date"
    case "date":
      if (moment.unix(unix).format("YYYY-MM-DD") !== "Invalid date")
        return moment.unix(unix).format("YYYY-MM-DD")
      else return "Invalid date"
    case "dateTime":
    default:
      if (moment.unix(unix).format("YYYY-MM-DD hh:mm A") !== "Invalid date")
        return moment.unix(unix).format("YYYY-MM-DD hh:mm A")
      else return "Invalid date"
  }
}

export const formatDateField = (date, field) => {
  switch (field) {
    case "month":
      if (moment(date).format("MMM") !== "Invalid date")
        return moment(date).format("MMM")
      else return null
    case "date":
      if (moment(date).format("DD") !== "Invalid date")
        return moment(date).format("DD")
      else return null
    case "year":
      if (moment(date).format("YYYY") !== "Invalid date")
        return moment(date).format("YYYY")
      else return null
    default:
      return null
  }
}

export const convertAllDateFieldsToDate = ({
  formFields = [],
  data,
  format = "YYYY-MM-DD",
}) => {
  let dateFields = formFields
    ?.filter((field) => field?.formFieldType?.includes("date"))
    .map((field) => field.name)

  Object.keys(data).forEach((key) => {
    if (dateFields?.includes(key)) {
      let { month, date, year } = data[key]
      let dateString = `${year.value || year}-${month.value || month}-${
        date.value || date
      }`
      dateString =
        moment(dateString).format(format) !== "Invalid date"
          ? moment(dateString).format(format)
          : ""
      data[key] = dateString
    }
  })
}
