export const headers = [
  "created",
  "mhpEmail",
  "zendeskId",
  "mhpLicense",
  "clientEmail",
  "startDateTime",
  "consultMedium",
  "consultInstance",
  "consultReason",
  "history",
  "otherNotes",
  "diagnosis",
  "diagnosisVisibleToClient",
  "recommendations",
  "followUpDateMHP",
  "specificRecommendation",
  // "drug",
  // "signa",
  "consultStatus",
  "leaveRecommendation",
  "endorsement",
  "specificProblemIssue",
  "specificProblemIssueDetails",
  "causeOfDistress",
  "hasConsultedWithPsychOutsideProgram",
  "hasConsultedWithPsychInProgram",
  "previousPsychName",
  "hasBeenHospitalized",
  "isTakingPsychMeds",
  "psychMedsDetails",
  "isTakingNonPsychMeds",
  "nonPsychMedsDetails",
  "hasDrugAllergies",
  "drugAllergiesDetails",
  "hasBeenDiagnosed",
  "mentalIllnessDiagnosis",
  "kesslerNervous",
  "kesslerHopeless",
  "kesslerRestless",
  "kesslerDepressed",
  "kesslerEffort",
  "kesslerWorthless",
  "hasWishedToBeDead",
  "hasUsedGoogleMeet",
  "userId",
  "hadSuicidalThoughts",
  "hasThoughtSuicidalMethod",
  "hadSuicidalIntent",
  "hadSuicidalIntentWithPlan",
  "hadActedOnSuicidalThoughts",
  "preferredSchedule.firstChoiceSchedule.day",
  "preferredSchedule.firstChoiceSchedule.time",
  "preferredSchedule.secondChoiceSchedule.day",
  "preferredSchedule.secondChoiceSchedule.time",
  "preferredSchedule.thirdChoiceSchedule.day",
  "preferredSchedule.thirdChoiceSchedule.time",
]

export const essentialHeaders = [
  "mhpEmail",
  "zendeskId",
  "clientEmail",
  "consultInstance",
  "consultReason",
  "diagnosisVisibleToClient",
  "recommendations",
  "tags",
  "userId",
]

export const dateHeaders = ["created", "startDateTime", "followUpDateMHP"]

export const headerTypes = {
  created: "string",
  mhpEmail: "string",
  zendeskId: "number",
  mhpLicense: "number",
  clientEmail: "string",
  startDateTime: "string",
  consultMedium: "string",
  consultInstance: "string",
  consultReason: "string",
  history: "string",
  otherNotes: "string",
  internalDiagnosis: "string",
  diagnosis: "string",
  recommendations: "string",
  followupDate: "string",
  specificRecommendation: "string",
  drug: "string",
  signa: "string",
  consultStatus: "string",
  leaveRecommendation: "string",
  endorsement: "string",
  specificProblemIssue: "string",
  specificProblemIssueDetails: "string",
  causeOfDistress: "string",
  hasConsultedWithPsychOutsideProgram: "boolean",
  hasConsultedWithPsychInProgram: "boolean",
  previousPsychName: "string",
  hasBeenHospitalized: "boolean",
  isTakingPsychMeds: "boolean",
  psychMedsDetails: "string",
  isTakingNonPsychMeds: "boolean",
  nonPsychMedsDetails: "string",
  hasDrugAllergies: "boolean",
  drugAllergiesDetails: "string",
  hasBeenDiagnosed: "boolean",
  mentalIllnessDiagnosis: "string",
  kesslerNervous: "string",
  kesslerHopeless: "string",
  kesslerRestless: "string",
  kesslerDepressed: "string",
  kesslerEffort: "string",
  kesslerWorthless: "string",
  hasWishedToBeDead: "string",
  hasUsedGoogleMeet: "string",
  userId: "number",
  hadSuicidalThoughts: "boolean",
  hasThoughtSuicidalMethod: "boolean",
  hadSuicidalIntent: "boolean",
  hadSuicidalIntentWithPlan: "boolean",
  hadActedOnSuicidalThoughts: "boolean",
  "preferredSchedule.firstChoiceSchedule.day": "string",
  "preferredSchedule.firstChoiceSchedule.time": "string",
  "preferredSchedule.secondChoiceSchedule.day": "string",
  "preferredSchedule.secondChoiceSchedule.time": "string",
  "preferredSchedule.thirdChoiceSchedule.day": "string",
  "preferredSchedule.thirdChoiceSchedule.time": "string",
}
