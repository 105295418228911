export const data = (consultations) => {
  return consultations?.map((consultation) => {
    return [
      {
        label: "Zendesk ID",
        data: consultation?.zendeskId || "N/A",
        type: "string",
      },
      {
        label: "Client Name",
        data: consultation?.clientName || "N/A",
        type: "string",
      },
      {
        label: "MHP Email",
        data: consultation?.mhpEmail || "N/A",
        type: "string",
      },
      {
        label: "Risk Assessment",
        data: consultation?.diagnosis?.value || "N/A",
        type: "string",
      },
      {
        type: "component",
        data: !consultation?.firestoreDocumentId ? "Create" : "Update",
        type: "string",
      },
    ]
  })
}
